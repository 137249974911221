/*
 * Format example: March 7
 */
export const fullMonthDay = 'MMMM D';

/*
 * Thursday, March 07, 2024
 */
export const fullWeekday = 'dddd, MMMM DD, YYYY';

/*
 * Format example EN: March 07, 2024
 * Format example FR: 7 mars 2024
 */
export const fullDateEn = 'MMMM DD, YYYY';
export const fullDateFr = 'D MMMM YYYY';

/*
 * Format example EN: Mar 7th, 2024
 * Format example FR: 7 Mar. 2024
 */
export const abbrevMonthOrdinalDayYearEn = 'MMM Do, YYYY';
export const abbrevMonthOrdinalDayYearFr = 'D MMM YYYY';

/*
 * Format example EN: March 7th, 2024
 * Format example FR: 7 mars 2024
 */
export const ordinalDayFullMonthYearEn = 'MMMM Do, YYYY';
export const ordinalDayFullMonthYearFr = 'Do MMMM YYYY';

/*
 * Format example: 07/03/2024
 */
export const slashSeparatedDate = 'MM/DD/YYYY';

/*
 * Format example: 2024/03/07
 */
export const slashSeparatedYearFirst = 'YYYY/MM/DD';

/*
 * Format example: 07-03-2024
 */
export const hyphenSeparatedDate = 'MM-DD-YYYY';

/*
 * Format example: 2024-03-07
 */
export const hyphenSeparatedYearFirst = 'YYYY-MM-DD';

/*
 * Format example: 2024-03-07T00:00:00Z
 */
export const dateTimeUtc = 'YYYY-MM-DDT00:00:00[Z]';

