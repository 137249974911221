import { gql } from '../../__generated__';

export const GET_CHEQUES = gql(`
query GetCheques($input: ChequesInput!) {
    cheques(input: $input) {
      cheques {
        chequeDate
        chequeNumber
        chequeStatus
        payee
        totalPaymentDollars
      }
    }
  }
`);

export const GET_ENTITLEMENTS = gql(`
query GetRebatesOverview($input: OverviewInput!) {
  rebatesOverview(input: $input) {
    rebateEntitlements {
      entitlements {
        id
        acres
        active
        cropYear
        entitledAmount
        paidAmount
        product {
          sku
          name
          mainGroup
        }
        program {
          id
          name
          type
        }
      }
      totalCount
    }
  }
}
`);

export const GET_DETAILS = gql(`
query GetRebateDetails($input: DetailsInput!) {
  rebateDetails(input: $input) {
    rebatePurchases{
      purchases { 
        acres
        chequeNumber
        product {
          name
          sku
        }
        programs {
          id
          name
          type
        }
        purchase {
          id
          invoiceDate
          invoiceId
          purchaser
          retailer {
            id
            alias
            name
            address {
              city
              province
            }
          }
        }
      }
      totalCount
    }
  }
}
`)