import { FC } from 'react';
import { FlagKeys } from '../../lib/constants/launch-darkly';
import LaunchDarklyWrapper from '../../components/_shared/LaunchDarklyWrapper';
import { LDIdentityType } from '../../components/_shared/LaunchDarklyWrapper/LaunchDarklyWrapper.types';
import { PageRoutes } from '../../lib/constants/react-router';
import SplashScreen from './SplashScreen/SplashScreen';
import { usePageTitle } from '../../lib/hooks/usePageTitle';

const BayerRewardsCalculator: FC = () => {
  usePageTitle('bayer-value-calculator');

  return (
    <LaunchDarklyWrapper
      flagKey={FlagKeys.CALCULATOR_PAGE}
      identityType={LDIdentityType.ID}
      redirectToPage={PageRoutes.REWARDS}
    >
      <SplashScreen />
    </LaunchDarklyWrapper>
  );
};

export default BayerRewardsCalculator;
