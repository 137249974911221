import Button from '../../../components/_shared/Button';
import calculatorImg from '../../../assets/images/bayer-value/rewards-calculator.png';
import { ChevronRightIcon } from '../../../assets/icons';
import { FC } from 'react';
import styles from './SplashScreen.module.scss';
import { useTranslation } from 'react-i18next';

const SplashScreen: FC = () => {
  const { t } = useTranslation();

  const onNextButtonClick = () => {};

  return (
    <div className={styles['splash-screen']}>
      <img
        src={calculatorImg}
        alt={t('bayer-value.calculator.image-alt')}
        className={styles['splash-screen__image']}
      />
      <h1 className={styles['splash-screen__title']}>
        {t('bayer-rewards-calculator.splash-screen-heading')}
      </h1>
      <p className={styles['splash-screen__subtitle']}>
        {t('bayer-rewards-calculator.splash-screen-subtitle')}
      </p>
      <Button
        onClick={onNextButtonClick}
        icon={ChevronRightIcon}
        className={styles['splash-screen__button']}
      >
        {t('general.get-started')}
      </Button>
    </div>
  );
};

export default SplashScreen;
