import { collapseElement, expandElement } from '../../../lib/utils/animations';
import { FC, ReactNode, SVGProps, useEffect, useRef, useState } from 'react';

import { ArrowDropDownIcon } from '../../../assets/icons';
import cx from 'classnames';
import styles from './Accordion.module.scss';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

export interface Props {
  id?: string;
  classNames?: {
    container?: string;
    innerContainer?: string;
  };
  title: string;
  children?: ReactNode;
  defaultOpen?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
  Icon?: FC<SVGProps<SVGSVGElement>>;
}

const Accordion: FC<Props> = ({
  id,
  classNames,
  title,
  children,
  defaultOpen = false,
  onExpand,
  onCollapse,
  Icon = ArrowDropDownIcon,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const { hash } = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);
  const accordionId = useRef<string>(id || uuid());

  useEffect(() => {
    if (!defaultOpen) {
      (contentRef.current as HTMLElement).style.display = 'none';
    }
  }, []);

  useEffect(() => {
    const elementId = hash.replace('#', '');
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        setIsOpen(elementId === accordionId.current);
        element.scrollIntoView();
      }
    }, 100);
  }, [hash]);

  const toggleItem = () => {
    const isCurrentlyOpen = !isOpen;
    setIsOpen(isCurrentlyOpen);

    if (isCurrentlyOpen) {
      expandElement(contentRef.current as HTMLElement, () => {
        onExpand?.();
      });
    } else {
      collapseElement(contentRef.current as HTMLElement, () => {
        onCollapse?.();
      });
    }
  };

  return (
    <div
      className={cx(classNames?.container, styles['accordion-item'], {
        [styles['accordion-item--active']]: isOpen,
      })}
      id={accordionId.current}
    >
      <button
        type="button"
        className={cx(styles['accordion-item__toggle'])}
        onClick={toggleItem}
        aria-expanded={isOpen}
        aria-controls={`accordion-panel-${accordionId.current}`}
      >
        <span
          id={`accordion-heading-${accordionId.current}`}
          className={cx(styles['accordion-item__title'])}
        >
          {title}
        </span>
        <Icon className={cx(styles['accordion-item__icon'])} aria-hidden="true" />
      </button>
      <div
        className={cx(styles['accordion-item__content'])}
        role="region"
        aria-hidden={!isOpen}
        id={`accordion-panel-${accordionId.current}`}
        aria-labelledby={`accordion-heading-${accordionId.current}`}
        ref={contentRef}
      >
        <div className={cx(classNames?.innerContainer, styles['accordion-item__content-inner'])}>
          {children}
        </div>
      </div>
    </div>
  );
};

Accordion.displayName = 'Accordion';

export default Accordion;
