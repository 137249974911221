import { FC, ReactNode } from 'react';
import StepsWrapperFooter, { CTA } from './StepsWrapperFooter';
import cx from 'classnames';
import SettingsSkeleton from '../../../components/_shared/SettingsSkeleton';
import styles from './StepsWrapper.module.scss';

export interface Props {
  title: string;
  subTitle: string;
  children: ReactNode;
  back?: CTA;
  submit?: CTA;
  className?: string;
  loadingQuery?: boolean;
}

const StepsWrapper: FC<Props> = ({
  title,
  subTitle,
  children,
  back,
  submit,
  className,
  loadingQuery,
}) => (
  <div className={cx(styles['steps-wrapper'])}>
    <div className={cx(styles['steps-wrapper__container'])}>
      <div className={cx(styles['steps-wrapper__form'])}>
        {loadingQuery ? (
          <SettingsSkeleton />
        ) : (
          <>
            <div className={cx(styles['steps-wrapper__header'])}>
              <h1 className={cx(styles['steps-wrapper__title'])}>{title}</h1>
              <p className={cx(styles['steps-wrapper__sub-title'])}>{subTitle}</p>
            </div>
            <div className={cx(styles['steps-wrapper__content'], className)}>{children}</div>
          </>
        )}
      </div>
    </div>
    <StepsWrapperFooter secondaryCta={back} primaryCta={submit} />
  </div>
);

StepsWrapper.displayName = 'StepsWrapper';

export default StepsWrapper;
