import { ButtonThemes, MODAL_FOCUSABLE_ELEMENTS_SELECTOR } from '../../../lib/constants/components';
import { KEY_ESCAPE, KEY_TAB } from 'keycode-js';
import { useEffect, useLayoutEffect, useRef } from 'react';
import Button from '../Button';
import { CrossIcon } from '../../../assets/icons';
import cx from 'classnames';
import { Language } from '../../../lib/constants/i18n';
import { ModalProps } from './Modal.types';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';
import { useEventListener } from '../../../lib/hooks';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const Modal = ({
  isVisible,
  hide,
  classNames,
  children,
  triggerRef,
  title,
  headerContent,
  primaryCta,
  secondaryCta,
  customFooter,
}: ModalProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const headingId = useRef(uuid());
  const innerRef = useRef<HTMLDivElement | null>(null);

  const getFocusableElements = () =>
    [
      // @ts-ignore
      ...innerRef.current.querySelectorAll(MODAL_FOCUSABLE_ELEMENTS_SELECTOR),
    ].filter((el) => !el.hasAttribute('disabled'));

  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible]);

  useLayoutEffect(() => {
    if (isVisible) {
      const keyboardFocusableElements = getFocusableElements();
      const firstElement = keyboardFocusableElements[0];
      if (firstElement) {
        firstElement.focus();
      } else {
        // @ts-ignore
        innerRef.current.focus();
      }
    }
  }, [isVisible]);

  const hideModal = () => {
    hide();
    triggerRef?.focus();
  };

  useEventListener('keydown', (e: any) => {
    if (!isVisible) return undefined;
    const { keyCode, shiftKey } = e;

    if (keyCode === KEY_ESCAPE) {
      hideModal();
    }
    // TAB KEY
    if (keyCode === KEY_TAB) {
      const keyboardFocusableElements = getFocusableElements();
      const firstElement = keyboardFocusableElements[0];
      const lastElement = keyboardFocusableElements[keyboardFocusableElements.length - 1];

      if (
        keyboardFocusableElements.length === 1 ||
        (!shiftKey && document.activeElement === lastElement)
      ) {
        firstElement.focus();
        return e.preventDefault();
      }

      if (shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        e.preventDefault();
      }
    }
    return undefined;
  });

  return isVisible
    ? ReactDOM.createPortal(
        <>
          <div
            className={cx(styles['backdrop'], classNames?.backDrop)}
            onClick={hideModal}
            role="presentation"
          ></div>
          <div
            className={cx(styles['modal-wrapper'], classNames?.modalWrapper)}
            role="dialog"
            aria-labelledby={headingId.current}
            aria-modal="true"
            ref={innerRef}
          >
            <div className={cx(styles['modal-header'], classNames?.header)}>
              <h2
                id={headingId.current}
                className={cx(classNames?.modalTitle, styles['modal-title'], {
                  [styles['modal-title--fr']]: language === Language.Fr,
                })}
              >
                {title}
              </h2>
              <button
                type="button"
                onClick={hideModal}
                className={cx(styles['close-button'])}
                aria-label={t('modals.close')}
              >
                <CrossIcon className={cx(classNames?.closeButton)} aria-hidden="true" />
              </button>
            </div>
            {headerContent && (
              <div className={cx(styles['modal-content-header'], classNames?.modalContentHeader)}>
                {headerContent}
              </div>
            )}
            <div className={cx(styles['modal-content'], classNames?.modalContent)}>{children}</div>
            <div className={cx(styles['modal-footer'], classNames?.footer)}>
              {customFooter && <div>{customFooter}</div>}
              <div className={cx(styles['modal-footer__wrapper'])}>
                {secondaryCta && (
                  <Button
                    type={secondaryCta.buttonType || 'button'}
                    onClick={secondaryCta.action}
                    theme={secondaryCta.buttonTheme || ButtonThemes.TEXT_LINK}
                    className={cx(styles['modal-footer__text-link'])}
                    disabled={secondaryCta.disabled}
                    icon={secondaryCta.buttonIcon}
                    iconPosition={secondaryCta.buttonIconPosition}
                    iconClassName={secondaryCta.buttonIconClassName}
                  >
                    {secondaryCta.label}
                  </Button>
                )}
                {primaryCta && (
                  <Button
                    id={primaryCta.id}
                    type={primaryCta.buttonType || 'button'}
                    formID={primaryCta?.buttonFormId}
                    onClick={primaryCta.action}
                    disabled={primaryCta.disabled}
                    icon={primaryCta.buttonIcon}
                    iconPosition={primaryCta.buttonIconPosition}
                    iconClassName={primaryCta.buttonIconClassName}
                  >
                    {primaryCta.label}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;
};

export default Modal;
