import { CartesianTickOptions } from 'chart.js';

export enum ChartType {
  LINE = 'line',
  STACKED = 'stacked',
}

export enum Color {
  RED = '#de0043',
  DARK_BLUE = '#10384f',
  LIGHT_BLUE = '#89d329',
  GREEN = '#00bcff',
  PURPLE = '#6a4c72',
  BLACK = '#0000000',
  PINK = '#ff7596',
  ORANGE = '#f9780d',
  SECONDARY_COLOR_100 = '#10384FBA',
  SURFACE_COLOR_LOW = '#10384F1F',
  WHITE = '#ffffff',
}

export const LineColors = [
  Color.RED,
  Color.DARK_BLUE,
  Color.LIGHT_BLUE,
  Color.GREEN,
  Color.PURPLE,
  Color.PINK,
  Color.ORANGE,
];

// Axis's label style.
export const ticks = (isXs: boolean): Partial<CartesianTickOptions> => ({
  padding: isXs ? 10 : 24,
  color: Color.SECONDARY_COLOR_100,
  font: {
    size: 14,
  },
});

// Sets the styles for X scale lines, adds the ticks styles.
export const xScale = (isXs: boolean) => ({
  grid: {
    drawBorder: false,
    display: false,
  },
  ticks: ticks(isXs),
});

/**
 * Sets the styles for Y scale lines, adds the ticks styles, and its title.
 * @param type  Acre | Mrsp
 */
export const yScale = (isXs: boolean, type: ChartType, text?: string) => ({
  grid: {
    color: Color.SURFACE_COLOR_LOW,
    drawBorder: false,
    tickBorderDash: [2, 1],
    borderDash: [2, 1],
  },
  ticks: ticks(isXs),
  title: {
    text,
    display: !isXs && type === ChartType.LINE,
    color: Color.SECONDARY_COLOR_100,
    font: {
      weight: 'bold',
      size: 14,
    },
  },
});
