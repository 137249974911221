import { CheckmarkValidationIcon, IndeterminateIcon } from '../../../assets/icons';
import { forwardRef, ReactNode, useRef } from 'react';
import cx from 'classnames';
import styles from './Checkbox.module.scss';
import { v4 as uuid } from 'uuid';

export interface Props {
  disabled?: boolean;
  label: string | ReactNode;
  indeterminate?: boolean;
  value: React.InputHTMLAttributes<HTMLInputElement>['value'];
  name: string;
  onChange(e?: any): void;
  id?: string;
  className?: {
    wrapper?: string;
    label?: string;
  };
  hasError?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      disabled = false,
      indeterminate = false,
      value,
      label,
      name,
      onChange,
      id = undefined,
      className,
      hasError,
    },
    ref
  ) => {
    const checkboxId = useRef<string>(id || uuid());
    const labelId = useRef<string>(uuid());

    return (
      <label className={cx(styles['checkbox'], className?.wrapper)} htmlFor={checkboxId.current}>
        <div className={cx(styles['checkbox__circle'])}>
          <input
            className={cx(styles['checkbox__input'], 'sr-only')}
            type="checkbox"
            onChange={onChange}
            disabled={disabled}
            value={value}
            name={name}
            ref={ref}
            id={checkboxId.current}
            aria-label={indeterminate ? 'checkbox state is indeterminate' : ''}
            aria-describedby={labelId.current}
          />
          <span
            className={cx(styles['checkbox__box'], {
              [styles['checkbox__box--disabled']]: !indeterminate && disabled,
              [styles['checkbox__box--error']]: hasError,
            })}
          >
            {!indeterminate ? (
              <CheckmarkValidationIcon
                className={cx([styles['checkbox__check']], {
                  [styles['checkbox__check--disabled']]: disabled,
                  [styles['checkbox__check--error']]: hasError,
                })}
                aria-hidden="true"
              />
            ) : (
              <IndeterminateIcon
                className={cx(styles['checkbox__indeterminate'], {
                  [styles['checkbox__indeterminate--disabled']]: disabled,
                  [styles['checkbox__check--error']]: hasError,
                })}
                aria-hidden="true"
              />
            )}
          </span>
        </div>
        <span id={labelId.current} className={cx(styles['checkbox__label'], className?.label)}>
          {label}
        </span>
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
